import * as React from 'react'

import { useQuery } from '@apollo/client'

import { FomoText, PortfolioDisplay } from '../components'
import {
  ASSETS_PRICES_QUERY,
  ASSETS_UNSPENT_INTERESTS_QUERY,
  BASE_APYS_QUERY,
  MARKET_ASSETS_QUERY,
  USER_OPERATIONS_QUERY,
} from '../queries'
import { asOperationsArray } from '../services'

import type {
  AssetsPricesData,
  AssetsPricesVars,
  AssetsUnspentInterestsData,
  AssetsUnspentInterestsVars,
  BaseApysData,
  BaseApysVars,
  MarketAssetsData,
  MarketAssetsVars,
  ShortRange,
  UserOperationsData,
  UserOperationsVars,
} from '../queries'

export const PortfolioView = ({ graphUnits }: { graphUnits: string[] }) => {
  const [range, setRange] = React.useState<ShortRange>('24h')
  const [graphUnit, setGraphUnit] = React.useState<string>(graphUnits[0])

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<AssetsPricesData, AssetsPricesVars>(ASSETS_PRICES_QUERY, {
      variables: {
        quoteSymbol: graphUnit,
        range,
      },
    })

  const { loading: interestsLoading, data: interestsData } =
    useQuery<AssetsUnspentInterestsData, AssetsUnspentInterestsVars>(
      ASSETS_UNSPENT_INTERESTS_QUERY)

  const { loading: baseApysLoading, data: baseApysData } =
    useQuery<BaseApysData, BaseApysVars>(BASE_APYS_QUERY)

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const operations = asOperationsArray(operationsData?.userOperations)

  return (
    <PortfolioDisplay
      loading={assetsLoading
        || pricesLoading
        || baseApysLoading
        || operationsLoading
        || interestsLoading}
      assetsData={assetsData}
      pricesData={pricesData}
      interestsData={interestsData}
      baseApysData={baseApysData}
      operations={operations}
      range={range}
      setRange={setRange}
      graphUnits={graphUnits}
      graphUnit={graphUnit}
      setGraphUnit={setGraphUnit}
      fomoText={<FomoText />}
    />
  )
}
