import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid, Stack, Typography } from '@mui/material'
import { navigate } from '@reach/router'

import {
  AppContainer,
  ButtonsContainer,
  Currency,
  CurrencySkeleton,
  FundDisplay,
  GRAPH_TYPES,
  GraphType,
  GraphTypeButtons,
  Loading,
  RangeButtons,
} from '../components'
import { ASSETS_PRICES_QUERY } from '../queries'

import type {
  AssetPrices,
  AssetsPricesData,
  AssetsPricesVars,
  Fund,
  LongRange,
} from '../queries'

type FundGraphButtonsProps = {
  hasNoFunds: boolean
  graphType: string
  setGraphType: (value: GraphType) => void
  range: LongRange
  setRange: (value: LongRange) => void
}

const FundGraphButtons = ({
  hasNoFunds,
  graphType,
  setGraphType,
  range,
  setRange,
}: FundGraphButtonsProps) => (
  <ButtonsContainer md={6}>
    <GraphTypeButtons
      keys={GRAPH_TYPES}
      graphType={graphType}
      setGraphType={setGraphType}
      containerProps={{ xs: true, justifyContent: 'flex-end' }}
      disabled={hasNoFunds}
    />
    <RangeButtons<LongRange>
      keys={['24h', '7d', '30d', '1y', 'all']}
      range={range}
      setRange={setRange}
      containerProps={{ justifyContent: 'right' }}
      disabled={hasNoFunds || graphType !== 'TOTAL_AMOUNT'}
    />
  </ButtonsContainer>
)

type BalanceProps = {
  descriptionText: string
  loading: boolean
  currency: string
  digits?: number
  value: number
}

export const FundBalance = ({
  descriptionText,
  loading,
  currency,
  digits,
  value,
}: BalanceProps) => (
  <AppContainer
    sm={12}
    md={6}
    sx={{ p: 3, backgroundColor: 'primary.dark', color: 'common.white' }}
  >
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
    >
      <Typography
        variant='h4'
        component='p'
        fontWeight={600}
        lineHeight='1.2'
        textAlign='center'
      >
        {loading ? (
          <CurrencySkeleton animation='wave' />
        ) : (
          <Currency
            currency={currency}
            digits={digits}
            value={value}
          />
        )}
      </Typography>
    </Stack>
    <Typography
      variant='h6'
      component='p'
      textAlign='center'
    >
      {descriptionText}
    </Typography>
  </AppContainer>
)

type FundsDisplayProps = {
  funds: Fund[]
  assetPrices: AssetPrices[]
  graphType: GraphType
  mainFundsView?: boolean
}

const FundsDisplay = ({
  funds,
  assetPrices,
  graphType,
  mainFundsView,
}: FundsDisplayProps) => (funds.length > 0) ? (
  funds.map((fund, index) => (
    <FundDisplay
      key={index}
      fund={fund}
      graphType={graphType}
      assetPrices={assetPrices}
      onClick={() => navigate(`funds/${fund.id}`)}
    >
      <Typography sx={{ marginY: 'auto' }}>
        Monto total:
        {' '}
        <Currency
          currency='USD'
          digits={2}
          value={fund.balance}
        />
      </Typography>
    </FundDisplay>
  ))
) : (
  <AppContainer
    sx={{
      height: 280,
      p: 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    }}
  >
    {mainFundsView ? 'Presiona el botón para crear un fondo' : 'No hay fondos disponibles'}
  </AppContainer>
)

type FundsProps = {
  funds: Fund[]
  totalBalance: number
  mainFundsView?: boolean
}

export const FundsView = ({
  funds,
  totalBalance,
  mainFundsView,
}: FundsProps) => {
  const [range, setRange] = React.useState<LongRange>('24h')
  const [graphType, setGraphType] = React.useState<GraphType>('COMPOSITION')

  const { loading, data } =
    useQuery<AssetsPricesData, AssetsPricesVars>(ASSETS_PRICES_QUERY, {
      variables: {
        quoteSymbol: 'USD',
        range: range || '24h',
      },
    })

  return (
    <Grid
      container
      spacing={3}
      paddingBottom={12}
    >
      <FundBalance
        loading={false}
        descriptionText='Monto total'
        currency='USD'
        digits={2}
        value={totalBalance}
      />
      <FundGraphButtons
        hasNoFunds={funds.length === 0}
        graphType={graphType}
        setGraphType={setGraphType}
        range={range}
        setRange={setRange}
      />
      {(loading) ? (
        <Loading />
      ) : (
        <FundsDisplay
          funds={funds}
          assetPrices={data?.assetsPrices || []}
          graphType={graphType}
          mainFundsView={mainFundsView}
        />
      )}
    </Grid>
  )
}
